import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    DiJavascript1,
    DiNodejs,
    DiMongodb,
    DiGit,
    DiMysql,DiPostgresql,DiDjango
  
} from "react-icons/di";
import { FaStripe } from "react-icons/fa";
import {
    SiFirebase,
    SiStripe,
    SiFlask,SiSwagger
} from "react-icons/si";

function Backendstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDjango/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFlask/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPostgresql/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaStripe/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSwagger/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit/>
      </Col>
      </Row>
  );
}

export default Backendstack;