import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ServiceCard from "./ServicesCards";
import Particle from "../Particle";
import ai_app from "../../Assets/Services/Deconstructed robot-amico.svg";
import web_app from "../../Assets/Services/Programming-pana.svg"
import crm_sol from "../../Assets/Services/Visual data-amico.svg"

function Services() {
  return (
    <Container fluid className="service-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Services <strong className="purple">I Provide </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="service-card">
            <ServiceCard
              imgPath={ai_app}
              isBlog={false}
              title="Computer Vision and ML Development"
              description="I can teach machines how see and perceive according to different conditions using custom models deployed over edge and cloud. I can build quick and industry ready solutions for quality assurance, inventory management, security, safety detection, traffic and human surveys, retail analytics, footfall analytics and satellite imagery."
            />
          </Col>
          <Col md={4} className="service-card">
            <ServiceCard
              imgPath={web_app}
              isBlog={false}
              title="Mobile and Web Development"
              description="I can teach machines how see and perceive according to different conditions using custom models deployed over edge and cloud. I can build quick and industry ready solutions for quality assurance, inventory management, security, safety detection, traffic and human surveys, retail analytics, footfall analytics and satellite imagery."
            />
          </Col>
          <Col md={4} className="service-card">
            <ServiceCard
              imgPath={crm_sol}
              isBlog={false}
              title="Computer Vision and ML Development"
              description="I can teach machines how see and perceive according to different conditions using custom models deployed over edge and cloud. I can build quick and industry ready solutions for quality assurance, inventory management, security, safety detection, traffic and human surveys, retail analytics, footfall analytics and satellite imagery."
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Services;
