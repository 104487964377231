import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import tams from "../../Assets/Projects/tams.jpg";
import nhsp from "../../Assets/Projects/nhsp.jpg";
import nrap from "../../Assets/Projects/nrap.jpg";
import rsms from "../../Assets/Projects/rsms.jpg";
import ssas from "../../Assets/Projects/ssas.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Projects that I <strong className="purple">have worked on </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tams}
              isBlog={false}
              title="Features"
              description="Traffic Analysis and Management System provides survey for the type of vehicles (Sedans, Jeeps, Buses, Trucks etc), their flow rate at each intersection (e.g 40 Sedans North to East), Automatic Number Plate Recognition systems (ANPR), Heavy Vehicle Identification and Axles Detection."
              features = "Web app (React), Mobile App (React Native), Backend (NodeJS + Express), AI Events (Jetson Xavier), Database (Firebase + MongoDB) "
            
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nhsp}
              isBlog={false}
              title="Features"
              description="Neurawiz Healthcare Solutions Platform provides healthcare solutions using modern AI diagnoses techniques. It can do fundus image analysis, chest x-ray analysis, teeth & tongue disorder diagnosis, skin cancer detection and tumor identification, Xray analysis and disease diagnosis, NLP based report generation, Custom ERP solutions for Hospitals and Clinics."
              features = "Web app (React & Streamlit), Mobile App (React Native), Backend (NodeJS + Express), AI Backend (Flask + Docker + Cloud Run), Database (Firebase + MongoDB) "
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nrap}
              isBlog={false}
              title="Features"
              description="Neurawiz Retail Analytics Platform provides real time dashboard analytics with computation on edge as well as on cloud. It is suitable for shops and malls. It provides Inventory Management Platform (Smart Carts and Smart Cabinets), Heatmap and Zone Entry analytics/Footfall Analytics, Facial Analysis (Age, Gender, Emotions) and Person Reidentification systems"
              features = "Web app (React), Mobile App (React Native), Backend (NodeJS + Express), AI Events (Jetson Xavier), Database (Firebase + MongoDB) "
            />
          </Col>

        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rsms}
              isBlog={false}
              title="Features"
              description="Realtime Safety Management System includes a series of different modules for surveillance inside smart cities i.e Person fall detection, Street Fight identification system, Anti Theft Systems, Facial Recognition and Logging for sensitive areas, Weapon recognition system, Realtime dashboard and analytics for notifications."
              features = "Web app (React), Mobile App (React Native), Backend (NodeJS + Express), AI Events (Jetson Xavier), Database (Firebase + MongoDB) "
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ssas}
              isBlog={false}
              title="SSAS"
              description="Smart Surveillance and Alert Systems does safety analysis in realtime and provides notifications. It includes Fire and Smoke identification systems with alerts, Personal Protection Equipment identification, Construction Site Safety Analysis"
              features = "Web app (React), Mobile App (React Native), Backend (NodeJS + Express), AI Events (Jetson Xavier), Database (Firebase + MongoDB) "
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
