import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              PERSONAL <span className="purple"> INTRODUCTION </span>
            </h1>
            <p className="home-about-body">
              I have 3+ years of experience in AI, IOT and Full Stack Development
              <br />
              <br />Mostly I have worked on products focused on
              <i>
                <b className="purple"> Industry 4.0, Quality Assurance, Surveillance, Inventory Management and Retail Analytics </b>
              </i>
              <br />
              <br />
              I have 2 startups &nbsp;
              <i>
                <b className="purple">Aeyron </b> and
                {" "}
                <b className="purple">
                  Neurawiz {" "}
                </b>
              </i>
                and I have been offering myself as "CTO As A Service"

              <br />
              <br />
              I have a team which can create responsive websites, mobile apps and IOT ecosystems
              using <b className="purple">cloud</b> and
                <b className="purple">
                  {" "}
                  on premise technologies.
                </b>


            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/khizarhussain19"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/khizarhussain_"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/khizar-hussain-a21050153/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/khizarhussainn/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
