import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaAws } from "react-icons/fa";
import {

} from "react-icons/di";
import {
 
  
} from "react-icons/fi";
import {
    SiDocker,
    SiGooglecloud,
    SiMicrosoftazure,
    SiHeroku
} from "react-icons/si";

function Deploymentstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <FaAws/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGooglecloud/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftazure/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku/>
      </Col>

      </Row>
  );
}

export default Deploymentstack;